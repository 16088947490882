:root {
  --black: #111;
  --grey: #7d7d7d;
  --semiBlack: #222222;
  --green: #adff19;
  --white: #ffffff;
}
.text-bold{
  font-weight: 600;
  font-size: 18px;
}
.color-bigg.color-white {
  color: var(--white);
}

.bg-bigg.bg-black {
  background-color: var(--black);
}

.bg-bigg.bg-semiblack {
  background-color: var(--semiBlack);
}
.suggestions{
  position: absolute;
  background-color: #ccc;
width: 90%;
max-height: 330px;
z-index: 500;
overflow-y: scroll;
}
.suggestions-item{
  border-bottom-color:var(--semiBlack);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  padding-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  display: flex;
  z-index: 500;

  /* align-items: center; */
}
.btn.btn-bigg {
  background-color: var(--semiBlack);
  border-color: var(--semiBlack);
  color: var(--green);
  /* padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px; */

}
.column-list{
  column-count: 3;
}
.selected-tags{
  display: flex
}
.tag{
  background-color: var(--green);
  padding: 7px 14px;
  border-radius: 3px;
  margin-right: 15px;
  display: flex;
  position:relative;
}
.remove-tag{
height: 18px;
width: 18px;
border-radius: 20px;
display: flex;
align-items: center;
position: absolute;
justify-content: center;
border: none;
background-color: #111;
color: #fff;
top: -5px;
right: -5px;
}
.small-width {
  max-width: 100px; /* Ajusta el valor según lo necesites */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTags__suggestions{
  /* background-color: #ccc; */
  /* width: 400px; */
  /* position: absolute; */
  padding-top:5px;
  padding-bottom: 15px;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);

  background-color: #ddd;
  /* width: 400px; */
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  cursor: pointer;
  margin: 0;

}
.btn-bigg-delete {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.btn.btn-navlink-green {
  background-color: transparent;
  color: var(--green);
}
.bg-movement-list{
  background-color: var(--semiBlack);
  color: #fff;
  margin-bottom: 2px;
  border-radius: 4px;
  padding-top: 1px;
  /* padding-bottom: 1px; */
  text-align: center;
}
.btn.btn-navlink-green:hover,
.btn.btn-navlink-green:focus {
  color: var(--white);
}

.btn.btn-bigg:hover,
.btn.btn-bigg:focus {
  background-color: var(--semiBlack);
  border-color: var(--green);
  color: var(--green);
}

.btn.btn-bigg.btn-green {
  background-color: var(--green);
  border-color: var(--green);
  color: var(--semiBlack);
}

.btn.btn-bigg.btn-green:hover,
.btn.btn-bigg.btn-green:focus {
  border-color: var(--semiBlack);
}

.bigg-pagination .page-link,
.bigg-pagination .page-link.page-link-nohover:hover {
  border-color: var(--semiBlack);
  background-color: var(--semiBlack);
  color: var(--green);
}

.bigg-pagination .page-item.active .page-link,
.bigg-pagination .page-link:hover,
.bigg-pagination .page-link:focus {
  color: var(--semiBlack);
  background-color: var(--green);
  border-color: var(--green);
}
.custom-youtube-player {
  position: fixed;
  width: 450px;
  height: 300px;
  bottom: 40px;
  right: 40px;
  background: #000;
}
.custom-youtube-player .close,
.custom-youtube-player .handle {
  position: absolute;
  right: 0px;
  top: -30px;
  width: 32px;
  height: 32px;
  background: #000;
  color: #fff;
  opacity: 1;
  padding: 3px;
  cursor: pointer;
}
.custom-youtube-player .handle {
  right: 32px;
  padding-top: 8px;
  cursor: move;
}

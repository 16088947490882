.file-selector {
  align-items: center;
  border-color: inherit;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
}

.file-selector input {
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

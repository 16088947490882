.file-selector {
  align-items: center;
  border-color: inherit;
  border-style: dashed;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
}
.trainings-container{
  /* margin-top: 20px; */
  height: 170px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
}
.training-col{
  padding-top: 3px;
  padding-bottom: 3px;
  width: 50%; 
  box-sizing: border-box;
}
.goals-container{
height: 230px;
}
.goals-col{
  padding-top: 3px;
  padding-bottom: 3px;
  width: 25%; 
  box-sizing: border-box;
}
.file-selector input {
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

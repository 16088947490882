.body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.screen {
  background: #191919;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
}
.card {
  margin-bottom: 20px;
  border: none;
}

.box {
  width: 100%;
  padding: 40px;
  position: absolute;

  background: #191919;
  text-align: center;
  transition: 0.25s;
  margin-top: 100px;
}

.box input[type='text'],
.box input[type='password'] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #3498db;
  padding: 10px 10px;
  width: 250px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
}

.box h1 {
  color: white;
  text-transform: uppercase;
  font-weight: 500;
}

.box input[type='text']:focus,
.box input[type='password']:focus {
  width: 300px;
  border-color: #2ecc71;
}

.box input[type='submit'] {
  border: 0;
  background: none;
  display: block;
  margin: 20px auto;
  text-align: center;
  border: 2px solid #2ecc71;
  padding: 14px 40px;
  outline: none;
  color: white;
  border-radius: 24px;
  transition: 0.25s;
  cursor: pointer;
}

.box input[type='submit']:hover {
  background: #2ecc71;
}

.forgot {
  text-decoration: underline;
}
